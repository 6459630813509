[onsw-hotornot] {
  margin: 5px;
}
@media (min-width: 768px) {
  [onsw-hotornot] {
    margin: 10px 15px;
  }
}
[onsw-hotornot] {
  overflow: hidden;
}

[onsw-hotornot] > .box {
  overflow: hidden;
}

[onsw-hotornot] > .box > .ons-layout.textblock {
  display: none;
  position: absolute;
  top: calc(50% - 50px);
  transform: translateY(-50%);
  right: 30px;
  left: calc(50% + 220px);
  max-width: 320px;
  margin: 0 auto;
  color: #fff;
  font-size: 20px;
  text-shadow: 0 2px 3px #232323;
}

.ons-layout.textblock > .box > .ons-heading  {
  line-height: 1;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.ons-layout.textblock > .box > .ons-heading > .box {
  white-space: unset;
}

@media (min-width: 1280px) {
  body:not(.show-dialog) [onsw-hotornot] > .box > .ons-layout.textblock {
    display: block;
  }
}
@media (min-width: 1600px) {
  [onsw-hotornot] > .box > .ons-layout.textblock {
    display: block;
  }
}

.hotornot-pending {
  position: absolute;
  top: 0;
  bottom: 70%;
  left: 0;
  right: 0;
  width: 350px;
  height: 500px;
  overflow: hidden;
  margin: auto;
  padding: 25px;
  transition: transform .3s ease;
  transition-property: transform, left, right, border, border-radius;
  transition-delay: 1s;
}
@media (min-width: 768px) {
  .hotornot-pending {
    max-height: 80%;
    bottom: 100px;
  }
}

@media (max-width: 767px) {
  .noVotes .hotornot-pending {
    bottom: 0;
  }
  .hasVotes .hotornot-pending {
    bottom : 152px;
  }
  .hotornot-pending:not(.positive) {
    width: unset;
    height: unset;
  }
}

.hotornot-pending.pos1 {
  z-index: 1000;
}

.hotornot-pending.pos2 {
  transform: translateX(-60%) scale(.9);
}

.hotornot-pending.pos3 {
  transform: translateX(-105%) scale(.81);
}

.hotornot-pending.pos4 {
  transform: translateX(-140%) scale(.73);
}

.hotornot-pending.pos5 {
  transform: translateX(-165%) scale(.66);
}

.hotornot-pending.pos6 {
  transform: translateX(-165%) scale(.59);
}

@media (max-width: 767px) {
  .hotornot-pending {
    transition-delay: 1.8s;
  }
  .hotornot-pending.pos2 {
    transform: scale(.7);
  }

  .hotornot-pending.pos3 {
    transform: scale(.6);
  }

  .hotornot-pending.pos4 {
    transform: scale(.6);
  }

  .hotornot-pending.pos5 {
    transform: scale(.66);
  }

  .hotornot-pending.pos6 {
    transform: scale(.59);
  }
}

.hotornot-pending:not(.pos1) .hotbar {
  display: none;
}
.hotornot-pending:not(.pos1) > .box > [onsw-image].hotornot {
  height: calc(100% + 50px);
}
.hotornot-pending:not(.pos1) > .box > .online.hotornot  {
  display: none;
}
.hotornot-pending:not(.pos1) > .box > [onsw-item].hotornot  {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  font-size: 30px;
  background: #22222275;
  box-shadow: 0 3px 7px -4px #000;
  border-bottom: 1px solid rgba(0,0,0,.4);
  padding: 5px 25px;
  font-weight: 300;
  color: #fff;
  white-space: nowrap;
}

@keyframes blubber {
  from {
    transform: scale(1);
    transform-origin: 50% 50%;
    /*height: 500px;*/
    width: 100%;
    height: calc(100% - 152px);
    border-width: 0;
    border-radius: 0;
  }
  20% {
    /*height: 350px;*/
  }
  50% {
    transform: scale(1);
    transform-origin: 50% 50%;
    border-width: 2px;
    border-radius: 5px;
    width: 350px;
    height: 350px;
  }
  to {
    border-width: 7px;
    transform: scale(.229);
    transform-origin: 0 100%;
    height: 350px;
  }
}

@keyframes blubber-leave {
  from {
    width: 100%;
    height: calc(100% - 152px);
  }
  50% {
    width: 350px;
    height: 350px;
    border-radius: 3px;
    border-width: 2px;
  }
  to {
    width: 350px;
    height: 350px;
  }
}

@media (min-width: 768px) {
  @keyframes blubber {
    from {
      transform: scale(1);
      transform-origin: 50% 50%;
      height: 500px;
    }
    20% {
      height: 500px;
    }
    50% {
      transform: scale(1);
      transform-origin: 50% 50%;
      border-width: 2px;
      height: 500px;
    }
    to {
      border-width: 7px;
      transform: scale(.286);
      transform-origin: 0 100%;
      height: 350px;
    }
  }
}
.hotornot-pending.positive.ng-leave {
  transition: all 1s ease;
  transition-delay: 1s;
  transform-origin: 50% 50%;
  animation: blubber 2s ease forwards;
}

.hotornot-pending.positive.ng-leave-active {
  top: calc(100% - 350px - 108px);
  right: calc(100% - 350px - 40px);
  left: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .hotornot-pending.positive.ng-leave-active {
    top: calc(100% - 350px - 60px);
    right: calc(100% - 350px - 60px);
  }
}
.hotornot-pending.ng-leave .ons-item.tile {
  display: none;
}
.hotornot-pending.negative.ng-leave {
  transition-duration: 1s;
  transition-delay: 1s;
}
@media (max-width: 767px) {
  .hotornot-pending.negative.ng-leave {
    animation: blubber-leave 2s ease forwards;
  }
}
.hotornot-pending.negative.ng-leave-active {
  transform: translate(300%, -50%) rotate(260deg) scale(.7);
}

.hotornot-positive > .box {
  position: relative;
}
.hotornot-positive.ng-leave {
  transition: opacity 1s ease;
  opacity: 1;
}
.hotornot-positive.ng-leave-active {
  opacity: 0;
}
.hotornot-positive.ng-enter {
  transition: all 1s ease;
  transition-property: width, margin;
  transition-delay: 1s;
  opacity: 0;
  width: 0;
  margin: 0;
}
.hotornot-positive.ng-enter-active {
  opacity: 0;
  margin-right: 15px;
  width: 100px;
}

@media (max-width: 767px) {
  .hotornot-positive.ng-enter-active {
    margin-right: 10px;
    width: 80px;
  }
}

.hotornot-positive > .box > [onsw-item] {
  display: block !important;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  left: 5px;
  right: 5px;
  bottom: 5px;
  color: white;
  font-size: 10px;
  text-shadow: 0 1px 3px #222;
}
.hotornot-positive > .box > [onsw-item] > .box > .value {
  font-size: 10px;
}

.online.hotornot {
  position: absolute;
  left: 10px;
  bottom: 189px;
  opacity: 0;
  animation: ons-fade-in .5s ease forwards;
  animation-delay: 2s;
}

.online.hotornot > svg {
  width: 15px;
  height: 15px;
}

.ng-leave > .box > .online.hotornot {
  display: none;
}

.hotornot-positives {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #383838ba;
  animation: ons-fade-in 1.5s ease forwards;
  opacity: 0;
  animation-delay: .5s;
}

@media (max-width: 1023px) {
  .hotornot-positives {
    padding: 20px 15px 0;
  }
}

@media (max-width: 767px) {
  .ng-leave > .box > [onsw-item].hotornot,
  .ng-leave > .box > [onsw-text].from {
    display: none;
  }
}
.hotornot-pending.pos1 > .box > [onsw-text].hotornot.from {
  position: relative;
  top: -4px;
  margin-bottom: 5px;
}

.hotornot-positives > .box {
  white-space: nowrap;
}

.hotornot-positives > .box > [onsw-link] > .box {
  position: absolute;
  right: 0;
  top: 0;
  left: 70%;
  bottom: 0;
  max-width: 200px;
  height: 44px;
  border: none;
  overflow: hidden;
  margin: auto 10px;
}

@media (max-width: 1023px) {
  .hotornot-positives > .box > [onsw-link] {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
  .hotornot-positives > .box > [onsw-link] > .box {
    position: unset;
    height: 30px;
    padding: 0 15px;
    margin: unset;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  body.show-dialog .hotornot-positives > .box > [onsw-link],
  body.regard-shop-open .hotornot-positives > .box > [onsw-link] {
    display: block;
    padding: 10px 0;
    text-align: center;
  }
  body.show-dialog .hotornot-positives > .box > [onsw-link] > .box,
  body.regard-shop-open .hotornot-positives > .box > [onsw-link] > .box {
    position: unset;
    height: 30px;
    padding: 0 15px;
    margin: unset;
  }
}

.hotornot-positives > .box > [onsw-link] > .box:hover::before {
  left: -50%;
  transform: skewX(-5deg);
}
.hotornot-positives > .box > [onsw-link] > .box::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.2);
  transition: 0.5s;
  pointer-events: none;
}

.hotornot-positive {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  height: 80px;
  width: 80px;
  box-shadow: 5px 5px 8px -2px #0008;
}

@media (min-width: 1024px) {
  .hotornot-positive {
    margin-right: 15px;
    height: 100px;
    width: 100px;
  }
}

.hotornot-positive > .box  {
  transition: transform .2s ease;
}
.hotornot-positive > .box:hover  {
  transform: scale(1.1);
}
.hotornot-positive > .box > :not([onsw-image]):not(.icons) {
  display: none;
}

.hotornot-positive > .box > [onsw-image] > .box {
  padding-top: 100%;
}

.hotornot-pending > .box > [onsw-image].hotornot {
  margin: -25px -25px 0;
  transition: height .4s ease;
  transition-property: height,margin;
}

@media (min-width: 768px) {
  .hotornot-pending > .box > [onsw-image].hotornot {
    transition-delay: 1s;
  }
}

.hotornot-pending.ng-leave > .box > [onsw-image].hotornot {
  transition-delay: 0s;
}

.hotornot-pending > .box > [onsw-image].hotornot {
  height: calc(100% - 150px);
}

.hotornot-pending > .box  {
  height: 100%;
}

.hotornot-pending.ng-leave > .box > [onsw-image].hotornot {
  height: calc(100% + 50px);
}

.hotornot-pending > .box > [onsw-image].hotornot > .box {
  height: 100%;
  padding-top: unset;
}

.ons-item.hotornot.tile[ons-icon="::amateur"] > .box > .icon * {
  fill: white;
}

.hotbar {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  opacity: 0;
  animation: ons-fade-in .5s ease forwards;
  animation-delay: 1s;
}
.ng-leave > .box > .hotbar {
  display: none;
}
.hotbar > .box {
  padding: 20px  0;
  text-align: center;
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(246, 246, 246, 1) 47%, rgba(237, 237, 237, 1) 100%);
}
.hotbar > .box > .ons-item:first-child > .box {
  transform: translate(11px, -100%) scale(1.25);
  margin: 0;
}
.hotbar > .box > .ons-item:last-child > .box {
  transform: translate(-11px, -100%) scale(1.25);
  margin: 0;
}
.hotbar > .box > .ons-item:nth-child(3) > .box {
  transform: translateY(-10%) scale(1.3);
}
.hotbar > .box > .ons-item > .box {
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  padding: 7px;
  border: 1px solid #fefefe;
  border-radius: 25px;
  background: white;
  margin: 5px 10px;
  box-shadow: 0 0 8px #ccc;
}
.hotbar > .box > .ons-item > .box:hover {
  border: 1px solid #dedbdb;
}
.hotbar > .box > .ons-item > .box > .label { 
  display: none;
}
.hotbar > .box > .ons-item > .box:hover > .icon { 
  transform: scale(.85);
}
.hotbar > .box > .ons-item > .box > .icon {
  height: 34px;
  width: 34px;
}

.hotornot.icons > .icon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 60%;
  margin: auto;
  display: none;
  filter: drop-shadow(2px 4px 6px #0006);
  animation: large-symbol 2.5s ease forwards;
}

@keyframes large-symbol {
  from {
    transform: translateY(-100%);
    opacity: 1;
  }
  15% {
    transform: translateY(0);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.negative > .box > .hotornot.icons > .icon:first-child {
  display: block;
}
.positive > .box > .hotornot.icons > .icon:last-child {
  display: block;
}

.hotornot-positive > .box > .hotornot.icons > .icon:last-child {
  display: block;
  box-sizing: border-box;
  animation: none;
  background: white;
  border-radius: 14px;
  padding: 6px;
  height: 28px;
  width: 28px;
  bottom: unset;
  margin: unset;
  right: -8px;
  top: -8px;
  left: unset;
}
.hotornot-positive > .box > .hotornot.icons > .icon:last-child * {
  stroke-width: 5px;
}

[onsw-hotornot] .timer {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 340px;
  transform: translate(-50%,-50%);
  animation: ons-fade-in 1s ease forwards;
}

[onsw-hotornot] .timer > .box {
  margin: 3px;
  padding: 20px 80px;
}

.timer > .box > .ons-text > .box {
  text-shadow: 0 2px 5px #222;
  font-size: 32px;
  color: #fff;
  text-align: center;
  font-weight: 700;
}

[onsw-countdown].hotornot > .box {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 0 2px 5px #222;
  font-size: 100px;
}

[onsw-countdown].hotornot > .box > .value > span {
  padding: 0 20px;
}

@media (max-width: 1279px) {
  [onsw-countdown].hotornot > .box {
    font-size: 50px;
  }
  [onsw-hotornot] .timer > .box {
    padding: 15px 20px;
  }
  [onsw-countdown].hotornot > .box > .value > span {
    padding: 0 10px;
  }
  .timer > .box > .ons-text > .box {
    font-size: 24px;
  }
}

