rating-stars.rating-list .outer {
  font-size: medium;
}

rating-stars .outer {
  position: relative;
  font-size: large;
  color: grey;
}

rating-stars .outer::before, rating-stars .inner::before {
  content: '\2605\2605\2605\2605\2605'
}

rating-stars.rating-list .value {
  font-size: 14px;
  font-family: "Open Sans", serif;
}

rating-stars .outer > .inner {
  position: absolute;
  left: 0;
  color: gold;
  overflow: hidden;
}

rating-stars.voting .outer > .inner, rating-stars.voted .outer > .inner {
  color: #c00;
}
