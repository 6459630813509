@import url('../../../../node_modules/cropperjs/dist/cropper.css');

.profile-image-editor {
    >.layout-container {
        &.large {
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }
        .onsw-cropper {
            text-align: right;
            > img {
                display:block;
            }
            img {
                width: 100%;
                height: auto;
            }
            .crop-preview {
                margin-right: 10px;
                overflow: hidden;
                height: 80%;
            }
            .crop-pw {
                position: absolute;
                border: 0 groove #39f;
                &.normal {
                    border-width: 1px 0;
                    height: 75%;
                    width: 100%;
                    top: 50%;
                    left:0;
                    transform: translateY(-50%);
                }
                &.wide {
                    border-width: 0 1px;
                    height: 100%;
                    width: 75%;
                    top:0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .cropper-modal {
                opacity: 0.7;
            }
        }
        .preset-button {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    
}
