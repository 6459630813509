.image.payment-no-bonus {
  width: 100%;
}

/**
 * GLOBAL
 */
[onsw-bonus] > .box {
  position: relative;
  cursor: pointer;
}

[onsw-bonus] > .box > .ons-image > .box {
  background-size: auto 100%;
}

/**
 * HOME
 */
[onsw-bonus].home > .box > .ons-image > .box {
  padding-top: 45px;
}

@media (min-width: 768px) {
  [onsw-bonus].home > .box > .ons-image > .box {
    padding-top: 50px;
  }
}

@media (min-width: 1024px) {
  [onsw-bonus].home > .box > .ons-image {
    padding-bottom: 50px;
  }
  [onsw-bonus].home > .box > .marquee {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 1280px) {
  body:not(.show-dialog) [onsw-bonus].home > .box > .ons-image > .box {
    padding-top: 100px;
  }
}

@media (min-width: 1600px) {
  [onsw-bonus].home > .box > .ons-image > .box {
    padding-top: 100px;
  }
}

@media (min-width: 2400px) {
  [onsw-bonus].home > .box > .ons-image > .box {
    padding-top: 5.88%;
  }
}

/**
 * PAYMENT
 */
@media (max-width: 1023px) {
  .bonus-hook.payment {
    display: none;
  }
}

.bonus-hook.payment {
  margin-bottom: 15px;
}

[onsw-bonus].payment > .box > .ons-image > .box {
  padding-top: 215px;
}

[onsw-bonus].payment.bonus-valentine2023 > .box > .ons-image > .box {
  padding-top: 198px;
}

[onsw-bonus].payment.Easter2021 > .box > .ons-image > .box {
  background-size: cover;
}
[onsw-bonus].payment.valentine2022 > .box > [onsw-countdown] {
  display: none;
}

/**
 * OVERLAY
 */
[onsw-bonus].bonusOverlay > .box > .ons-image > .box {
  padding-top: 37.6%;
}

/**
 * OTM
 */
[onsw-bonus].otm > .box > .ons-image > .box {
  padding-top: 250%;
}

[onsw-bonus].otm > .box {
  position: unset;
}
