@import '../layout/list.css';
@import '../layout/item.css';

/**
 * This is supposed to be inside of a panel.
 */
.achievement-packages.layout-list {
  grid-template-columns: repeat(auto-fill, minmax(var(--achievement-packages-width, 230px), 1fr));
  grid-auto-rows: var(--achievement-packages-height, 60px);
  gap: var(--list-gap, 10px);

  > .layout-item.achievement-package {
    grid-template-columns: var(--achievement-package-icon-size, 60px) auto min-content;
    padding: var(--achievement-package-padding, 10px);
    gap: var(--achievement-package-gap, 0 10px);

    &.notAvailable {
      opacity: 0.3;
      cursor: not-allowed;
    }

    > svg {
      grid-area: 1 / 1 / 3 / 2;
      transform: scale(0.8);
      transform-origin: top;

      > g:first-child {
        fill: var(--achievement-package-icon-color, var(--item-color, var(--panel-color)));
      }
    }

    > .title {
      font: var(--achievement-package-title-font, var(--item-heading-font));
    }

    > input {
      grid-area: 1 / 3 / 3 / 4;
    }
  }
}
