@import '../layout/panel.css';

.layout-panel.achievement-info {
  > img {
    width: 100%;
  }

  &.large {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-flow: dense;

    > h1 {
      grid-column-end: span 2;
    }

    > img {
      grid-column-start: 2;
    }
  }
}
